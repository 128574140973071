// EditTags.tsx
import { ArrowBackIcon, CheckIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import React, { useEffect, useRef, useState } from 'react';
import { MdLabel } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'store/store';
import PendingTagMultiSelect from './PendingTagMultiSelect';

interface EditableContent {
  [key: string]: string;
}
const EditTags = () => {
  const { fileName }: any = useParams();
  const { displayName }: any = useParams();
  const { personaId }: any = useParams();
  const { persona }: any = useParams();
  const { partner }: any = useParams();
  const { documentId }: any = useParams();

  const [fileData, setFileData] = useState<any>(null);
  const [checkList, setCheckList] = useState<any>([]);
  const [fileTagsData, setFileTagsData] = useState<any[]>([]);
  const [tagsData, setTagsData] = useState<any[]>([]);
  const [tagsDataMain, setTagsDataMain] = useState<any[]>([]);
  const [tagsDataDisplay, setTagsDataDisplay] = useState<any[]>([]);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [loadingFileData, setLoadingFileData] = useState(true);
  const [loadingChunkData, setLoadingChunkData] = useState(true);
  const [isEditingTags, setIsEditingTags] = useState<number | null>(null);

  const toast = useToast();
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login, getToken } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [deletedChunks, setDeletedChunks] = useState<any[]>([]);  const [isEditingTags, setIsEditingTags] = useState(null);
  const [isEditingContent, setIsEditingContent] = useState(null);
  const [editableContent, setEditableContent] = useState<EditableContent>({});
  const ws = useRef<WebSocket | null>(null);

  const handleEditContent = (fileId: any, content: any) => {
    setEditableContent((prev) => ({ ...prev, [fileId]: content }));
  };

  const handleUpdateContent = async (file: any) => {
    const updatedContent = editableContent[file.id];

    // Update the state before making the API call
    const updatedFileData = {
      ...fileData,
      file_data: fileData.file_data.map((item: any) =>
        item.id === file.id ? { ...item, content: updatedContent } : item
      ),
    };

    setFileData(updatedFileData); // Update state with new content
    setIsEditingContent(null); // Close the editing state

    try {
      const updatePayload = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        filename: file.sourcefile,
        id: file.id,
        content: updatedContent,
        sourcepage: file.sourcepage,
      };

      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-chunk`,
        updatePayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data === true) {
        toast({
          title: 'Update Successful',
          description: 'The file chunk has been successfully updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });

        // Optionally refetch the file data from the server
        // fetchFileData(); // Refetch data to ensure consistency

        // sendWSMessage();
      } else {
        toast({
          title: 'Update Failed',
          description:
            'Failed to update the file chunk. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Error updating file chunk:', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while updating the file chunk. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  const [pendingChunk, setPendingChunk] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleDeleteClick = (chunk: { id: string; name: string }) => {
    setPendingChunk(chunk);
    onOpen();
  };

  const handleConfirmDelete = async () => {
    setIsEditingContent(null);
    if (pendingChunk) {
      // Store the chunk data in deletedChunks before removing
      // const chunkToDelete = [pendingChunk.id];

      // setDeletedChunks((prev) => [...prev, chunkToDelete]);

      // Remove the chunk data from all relevant states
      const newTagsData = tagsData.filter(
        (tag) => tag.chunk_id !== pendingChunk.id
      );
      const newTagsDataMain = tagsDataMain.filter(
        (tag) => tag.chunk_id !== pendingChunk.id
      );
      const newTagsDataDisplay = tagsDataDisplay.filter(
        (tag) => tag.chunk_id !== pendingChunk.id
      );

      setTagsData(newTagsData);
      setTagsDataMain(newTagsDataMain);
      setTagsDataDisplay(newTagsDataDisplay);

      // Update file data by removing the matching file ID
      const updatedFileData = {
        ...fileData,
        file_data: fileData.file_data.filter(
          (item: any) => item.id !== pendingChunk.id
        ),
      };

      setFileData(updatedFileData);
      onClose();

      // Optionally, call your delete function here

      setPendingChunk(null);
      await deleteChunk(pendingChunk.id);
    }
  };

  const deleteChunk = async (id: string) => {
    try {
      const requestBody = {
        partner: process.env.REACT_APP_PARTNER,
        persona: process.env.REACT_APP_PERSONA,
        file_id: id,
        auth: profile?.user?.sub,
        pending: true,
      };

      const token = await getToken();

      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file-chunk`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );

      if (response.data.delete === true) {
        toast({
          title: 'Delete Successful',
          description: 'The file chunk has been successfully deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Error deleting file chunk:', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while deleting the file chunk. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  const handleWSMessage = () => {
    if (!ws.current) return;
    ws.current.onmessage = (event) => {
      const message = event.data;
      const message_obj = JSON.parse(message);
      setLoadingFileData(false);
      if (message_obj?.is_last) {
        setLoadingChunkData(false);
        ws.current.close();
      }
      setFileData(message_obj);
      const tagsArray = message_obj.file_data.map((file: any) => file.tags);

      if (tagsArray && tagsArray.length > 0) {
        setFileTagsData(tagsArray[0]);
      }
    };
  };

  const sendWSMessage = async () => {
    const token = await getToken();

    const message = JSON.stringify({
      partner,
      persona,
      filename: fileName,
      access_token: token,
    });
    ws.current.send(message);
    handleWSMessage();
  };

  const connectWebSocket = () => {
    // setLoading(true);

    setLoadingFileData(true);
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-explore-data`
    );

    newWs.onopen = () => {
      console.log('WebSocket connected to explore-data');
      ws.current = newWs;
    };

    newWs.onclose = () => {
      console.log('WebSocket disconnected');
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error explore-data:', error);
      toast({
        title: 'Error connecting websocket',
        description: 'Failed to connect websocket. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  };

  const fetchTagsData = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${
          process.env.REACT_APP_LLM_API_URL
        }/get-tags-by-file-name?persona_id=${encodeURIComponent(
          personaId
        )}&filename=${encodeURIComponent(fileName)}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        // Map chunk_id to their corresponding tags
        const chunkIdToTags: any = {};
        response.data.data.forEach((item: any) => {
          if (!chunkIdToTags[item.chunk_id]) {
            chunkIdToTags[item.chunk_id] = [];
          }
          chunkIdToTags[item.chunk_id].push(item);
        });

        // setTagsData(chunkIdToTags);
        setTagsData(response.data.data);
        setTagsDataMain(response.data.data);
        setTagsDataDisplay(response.data.data);
      } else {
        toast({
          title: 'Error',
          description: 'Failed to fetch tags data.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Error fetching tags data:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while fetching tags data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  const fetchOptions = async () => {
    try {
      const token = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_LLM_API_URL}/get-persona-tags/${personaId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data: any = await response.json();

      const fetchedTags = data.map((tag: any) => ({
        priority: tag.priority,
        value: tag.tag,
        label: tag.tag_type_name,
        parent_tag_type_id: tag.parent_tag_type_id,
        parent_tag_type_name: tag.parent_tag_type_name,
      }));
      setOptions(fetchedTags);
    } catch (error) {
      console.error('Error fetching options:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while fetching options.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  useEffect(() => {
    if (!profile?.idToken || !profile?.userId) {
      login();
    } else if (profile?.isAdmin) {
      fetchOptions();
      // fetchFileData();

      connectWebSocket();
      sendWSMessage();
      fetchTagsData();
    }
  }, [fileName, personaId]);

  // useEffect(() => {
  //   if (!loadingFileData && fileData) {
  //     sendWSMessage();
  //   }
  // }, [loadingFileData]);

  const handleCancelTag = () => {
    setIsEditingTags(null);
  };

  const handleSaveTag = async (
    id: string,
    sourcepage: string,
    tags: string[]
  ) => {
    const requestBody = {
      partner,
      persona,
      filename: fileName,
      id,
      sourcepage,
      tags,
    };

    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-chunk-tag`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data) {
        toast({
          status: 'success',
          duration: 5000,
          position: 'top',
          isClosable: true,
          render: () => (
            <Box
              mx={'auto'}
              w={35}
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={2}
              borderRadius="full"
              bg="green.500"
            >
              <Icon as={CheckIcon} color="white" boxSize={4} />
            </Box>
          ),
        });
      }
    } catch (error) {
      console.error('Error saving tags', error);
      toast({
        title: 'Error',
        description: 'Failed to save tags.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  const handleUpdateTag = async (tags: any[]) => {
    const requestBody = {
      tags: tags,
    };

    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/approve-tags`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success === true) {
        toast({
          title: 'Success',
          description: 'Tags approved.',
          status: 'success',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error update tags', error);
      toast({
        title: 'Error',
        description: 'Failed to update tags status.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  const handleUpdateFile = async () => {
    const requestBody = {
      id: documentId,
    };

    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/approve-file`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success === true) {
        toast({
          title: 'Success',
          description: 'File Updated.',
          status: 'success',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error update file', error);
      toast({
        title: 'Error',
        description: 'Failed to update file.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  const handleApproveTags = async (tagsList: any, fileId: string) => {
    try {
      setCheckList((prevCheckList: any) => [...prevCheckList, fileId]);
      const updatedTagsData = tagsData.filter(
        (tag) => !tagsList.some((tagInList: any) => tagInList.id === tag.id)
      );
      setTagsData(updatedTagsData);
      // const updatedFileData = {
      //   ...fileData,
      //   file_data: fileData.file_data.map(
      //     (item: any) =>
      //       item.id === fileId
      //         ? { ...item, checked: true } // Add checked: true to the matching item
      //         : item // Keep the item unchanged if id doesn't match
      //   ),
      // };
      // setFileData(updatedFileData);
    } catch (error) {
      console.error('Error approving tags:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while approving the tags.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  const rejectTag = async (id: number) => {
    const requestBody = {
      id: id,
    };

    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/reject-tag`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success === true) {
        toast({
          title: 'Success',
          description: 'Tags removed successfully.',
          status: 'success',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error reject tags', error);
      toast({
        title: 'Error',
        description: 'Failed to reject tag.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  const handleRemoveTag = async (fileId: any, tagId: any, edited: any) => {
    // console.log(edited, tagId);
    // return;
    try {
      if (!edited) {
        await rejectTag(tagId);
      }
      const filteredTagsData = tagsData.filter((tag) => tag.id !== tagId);
      const filteredTagsDataMain = tagsDataMain.filter(
        (tag) => tag.id !== tagId
      );
      const filteredTagsDataDisplay = tagsDataDisplay.filter(
        (tag) => tag.id !== tagId
      );
      // console.log(filteredTagsDataDisplay);
      // console.log(filteredTagsDataDisplay);
      // return;

      setTagsData(filteredTagsData);
      setTagsDataMain(filteredTagsDataMain);
      setTagsDataDisplay(filteredTagsDataDisplay);
    } catch (error) {
      console.error('Error removing tag:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove tag.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    }
  };
  const handleSaveEditedTag = async (id: string, saveTags: any[]) => {
    // console.log(tagsData);
    // console.log(tagsDataMain);
    // console.log(id);
    // console.log(saveTags);
    setTagsData((prevData) => [...prevData, ...saveTags]);
    setTagsDataDisplay((prevData) => [...prevData, ...saveTags]);
    setTagsDataMain((prevData) => [...prevData, ...saveTags]);
    // console.log(tagsData);
    // console.log(tagsDataDisplay);

    const updatedFileData = {
      ...fileData,
      file_data: fileData.file_data.map(
        (item: any) =>
          item.id === id
            ? { ...item, checked: false } // Add checked: true to the matching item
            : item // Keep the item unchanged if id doesn't match
      ),
    };

    setFileData(updatedFileData);
    // setTagsData((prevTagsData) => [...prevTagsData, ...saveTags]);
  };

  const handleApproveAllTags = async () => {
    try {
      if (tagsData.length !== 0) {
        toast({
          title: 'Check Required',
          description: 'You need to Check all the file chunk.',
          status: 'warning',
          duration: 2000,
          position: 'top',
          isClosable: true,
        });
        return;
      }

      setLoadingChunkData(true);
      // console.log('tagsDataDisplay====================');
      // console.log(tagsDataDisplay);
      // console.log('tagsData====================');
      // console.log(tagsData);
      // setLoadingFileData(true);
      const tagsNotInTagsData = tagsDataMain.filter(
        (tag) => !tagsData.some((existingTag) => existingTag.id === tag.id)
      );
      const groupedTags = tagsNotInTagsData.reduce((acc, tag) => {
        if (!acc[tag.chunk_id]) {
          acc[tag.chunk_id] = [];
        }
        acc[tag.chunk_id].push(tag.tag);
        return acc;
      }, {});
      // Convert the grouped data to the desired format and process each chunk_id
      const result = Object.entries(groupedTags).map(([chunk_id, tags]) => {
        // Find the corresponding source page from fileData
        const source = fileData?.file_data.find(
          (chunk: any) => chunk.id === chunk_id
        );

        return {
          chunk_id,
          tags,
          sourcepage: source?.sourcepage, // Include sourcePage as an object
        };
      });
      // Extract the chunk_ids from the result array
      const resultChunkIds = result.map((item) => item.chunk_id);

      // Filter the fileData to include only items not in the result
      const missingChunks = fileData?.file_data.filter(
        (item: any) => !resultChunkIds.includes(item.id)
      );

      for (const element of result as any) {
        const concatenatedTags = Array.from(
          new Set([...(fileTagsData || []), ...element.tags])
        );
        await handleSaveTag(
          element.chunk_id,
          element.sourcepage,
          concatenatedTags
        );
      }

      for (const element of missingChunks as any) {
        await handleSaveTag(element.id, element.sourcepage, fileTagsData);
      }

      const filteredTagsData = tagsNotInTagsData.filter(
        (tag) => tag.edited !== true && Number.isInteger(tag.id)
      );

      // Usage example:
      if (filteredTagsData.length > 0) {
        await handleUpdateTag(filteredTagsData);
      }
      await handleUpdateFile();
      setLoadingFileData(null);
      history.push('/admin/files');
    } catch (error) {
      console.error('Error during tag approval:', error);
      toast({
        title: 'Error',
        description: 'There was an error during the approval process.',
        status: 'error',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  const handleGoBack = async () => {
    // if (tagsData.length !== 0) {
    //   toast({
    //     title: 'Check Required',
    //     description: 'You need to Check all the file chunk.',
    //     status: 'warning',
    //     duration: 2000,
    //     position: 'top',
    //     isClosable: true,
    //   });
    //   return;
    // }
    history.push('/admin/files');
  };

  // const handleTagChange = (fileId: string, newTags: string[]) => {
  //   console.log('clicked change');
  // };
  const handleRemoveFileBaseTag = (tagId: string) => {
    setFileTagsData((prevTags) => prevTags.filter((tag) => tag !== tagId));
  };

  const handleSelectionChange = (newSelectedOptions: string[]) => {
    console.log('Get new data on select');
  };

  return (
    <Box p={8} maxW="7xl" mx="auto">
      <IconButton
        icon={<ArrowBackIcon />}
        aria-label="Back"
        variant="outline"
        colorScheme="blue"
        onClick={handleGoBack}
        mb={4}
      />
      <Text fontSize="3xl" textAlign="center" mb={6}>
        File Name: {displayName}
      </Text>

      {loadingFileData ? (
        <Spinner size="xl" />
      ) : (
        <Box>
          {process.env.REACT_APP_NODE_ENV !== 'production' && (
            <Button
              colorScheme="blue"
              mb={4}
              onClick={handleApproveAllTags}
              isDisabled={loadingChunkData} // Disable the button when loadingChunkData is true
            >
              Approve All
            </Button>
          )}

          {fileTagsData.length > 0 && (
            <Box
              maxWidth="100%"
              mb={4}
              p={4}
              borderRadius="10px"
              overflow="hidden"
              textOverflow="ellipsis"
              lineHeight="1.2em"
            >
              <Text fontWeight="bold" mb={1}>
                Common File tags:
              </Text>
              {fileTagsData.map((tag) => (
                <Tag
                  // key={tag.id}
                  variant="subtle"
                  colorScheme="blue" // Remove the condition and use a single color scheme
                  mt={2}
                  maxWidth="100%" // Ensure the tag takes up the full available width
                >
                  <TagLeftIcon boxSize="12px" as={MdLabel} />
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton
                    onClick={() => handleRemoveFileBaseTag(tag)}
                  />
                </Tag>
              ))}
            </Box>
          )}

          {fileData?.file_data.map((file: any) => {
            const filteredTags = tagsDataDisplay.filter(
              (tag: any) => tag.chunk_id === file.id
            );
            const tagsArray = filteredTags.map((tag) => tag.tag);
            const isInCheckList = checkList.includes(file.id);

            return (
              <Box key={file.id} mb={4}>
                <Box p={6} borderWidth="1px" borderRadius="10px">
                  <Text fontWeight="bold" mb={2}>
                    ID: {file.id}
                  </Text>
                  <Text fontWeight="bold" mb={1}>
                    Item Details:
                  </Text>

                  {isEditingContent === file.id ? (
                    <>
                      <Textarea
                        border="2px solid"
                        borderRadius="10px"
                        p="15px 20px"
                        mb="28px"
                        minH="150px"
                        fontWeight="500"
                        value={editableContent[file.id] || file.content}
                        onChange={(e) =>
                          handleEditContent(file.id, e.target.value)
                        }
                      />
                      <ButtonGroup>
                        <Button
                          colorScheme="blue"
                          onClick={() => handleUpdateContent(file)}
                        >
                          Save Content
                        </Button>
                        <Button onClick={() => setIsEditingContent(null)}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <Box
                      p={4}
                      borderRadius="10px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      lineHeight="1.2em"
                      mb={4}
                    >
                      {file.content}
                    </Box>
                  )}

                  {!isEditingContent && (
                    <>
                      <Box
                        p={4}
                        borderRadius="10px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        lineHeight="1.2em"
                      >
                        {!isEditingTags && (
                          <>
                            {filteredTags.map((tag: any) => (
                              <Tag
                                key={tag.id}
                                variant="subtle"
                                colorScheme={
                                  tag.is_new_tag && tag.tag_status === 0
                                    ? 'green'
                                    : 'blue'
                                }
                                mt={2}
                                bg={
                                  tag.is_new_tag && tag.tag_status === 0
                                    ? '#d3d3d3'
                                    : undefined
                                }
                                color={
                                  tag.is_new_tag && tag.tag_status === 0
                                    ? '#333'
                                    : undefined
                                }
                              >
                                <TagLeftIcon boxSize="12px" as={MdLabel} />
                                <TagLabel>
                                  {tag.tag} - {tag.tag_type_name}
                                </TagLabel>
                                {!isInCheckList && (
                                  <TagCloseButton
                                    onClick={() =>
                                      handleRemoveTag(
                                        file.id,
                                        tag.id,
                                        tag?.edited
                                      )
                                    }
                                  />
                                )}
                              </Tag>
                            ))}
                          </>
                        )}
                      </Box>

                      <PendingTagMultiSelect
                        partner={partner}
                        persona={persona}
                        fileName={file.sourcefile}
                        sourcepage={file.sourcepage}
                        id={file.id}
                        selectedOptions={tagsArray}
                        onSelectionChange={(newSelectedOptions: string[]) =>
                          handleSelectionChange(newSelectedOptions)
                        }
                        isEditing={isEditingTags === file.id}
                        options={options}
                        setOptions={setOptions}
                        newOptions={
                          filteredTags.length > 0 ? filteredTags : null
                        }
                        onRemoveTag={handleRemoveTag}
                        onSaveTag={handleSaveEditedTag}
                        onCancel={handleCancelTag}
                      />

                      <ButtonGroup mt={4}>
                        {isEditingTags !== file.id && (
                          <Button
                            colorScheme="blue"
                            onClick={() =>
                              handleApproveTags(filteredTags, file.id)
                            }
                            isDisabled={
                              isInCheckList || filteredTags.length <= 0
                            }
                          >
                            {isInCheckList
                              ? 'Checked'
                              : filteredTags.length <= 0
                              ? 'Checked'
                              : 'Check Tag'}
                          </Button>
                        )}

                        {isEditingTags !== file.id &&
                          isEditingContent !== file.id && (
                            <Button
                              colorScheme="blue"
                              onClick={() => {
                                setIsEditingContent(file.id);
                                setIsEditingTags(null); // Disable tag editing when editing content
                              }}
                            >
                              Edit Content
                            </Button>
                          )}

                        {isEditingTags !== file.id && (
                          <Button
                            ml={4}
                            colorScheme="blue"
                            onClick={() => setIsEditingTags(file.id)}
                          >
                            Edit Tags
                          </Button>
                        )}

                        {isEditingTags !== file.id && (
                          <Button
                            colorScheme="red"
                            onClick={() =>
                              handleDeleteClick({
                                id: file.id,
                                name: file.name,
                              })
                            }
                          >
                            Delete
                          </Button>
                        )}

                        {/* AlertDialog for confirmation */}
                        <></>
                      </ButtonGroup>
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete the chunk? This action cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EditTags;
