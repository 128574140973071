import { Auth0Provider } from '@auth0/auth0-react';
import PaymentErrorAlert from 'components/payment/PaymentError';
import PaymentSuccessAlert from 'components/payment/PaymentSuccess';
import RedirectPage from 'components/redirect/Redirect';
import SignUp from 'components/signup/sign-up';
import AdminLayout from 'layouts/admin';
import Auth from 'layouts/auth';
import ClientLayout from 'layouts/client';
import Profile from 'profile/Profile';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import EditTags from 'views/admin/admin/EditTag';
import ExploreData from 'views/admin/admin/ExploreData';
import AdminFileList from 'views/admin/filelists/FilelIst';
import SignInCentered from 'views/auth/signIn';
import Pricing from 'views/client/pricing';
const MainPage: React.FC<any> = () => {
  return (
    <div>
      <BrowserRouter>
        {/* To get private route */}
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH_0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH_0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: `${
              window.location.origin
            }/redirect?path=${encodeURIComponent(window.location.pathname)}`,
          }}
        >
          <Switch>
            <Route path={`/auth`} component={Auth} />
            <Route path={`/compliance`} component={ClientLayout} />
            <Route path={`/pricing`} component={Pricing} />
            <Route path={`/profile`} component={Profile} />
            <Route path={`/redirect`} component={RedirectPage} />
            <Route path={`/signup`} component={SignUp} />
            <Route path={`/payment/success`} component={PaymentSuccessAlert} />
            <Route path={`/payment/error`} component={PaymentErrorAlert} />
            <Route path={`/sign-in`} component={SignInCentered} />
            <Route path={'/files'} component={AdminFileList} />
            <Route path={'/admin'} component={AdminLayout} />
            <Route
              path="/file/chunk/:partner/:persona/:personaId/:fileName/:displayName"
              component={ExploreData}
            />
            <Route
              path="/file/chunk-tags/:partner/:persona/:personaId/:fileName/:displayName/:documentId"
              component={EditTags}
            />
            <Route path={`/`} component={ClientLayout} />
          </Switch>
        </Auth0Provider>
      </BrowserRouter>
    </div>
  );
};

export default MainPage;
