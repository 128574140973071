import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { MdDelete, MdEdit, MdLabel } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface Partner {
  id: number;
  name: string;
  description: string | null;
  display_name: string | null;
  logo: string | null;
  base_url: string | null;
  site_map: string | null;
}

interface Persona {
  id: number;
  name: string;
  display_name: string;
}

interface TagType {
  id: number;
  name: string;
}

interface PersonaTag {
  persona_id: number;
  tag: string;
  priority: number;
  tag_type_id: number;
  tag_type_name: string;
}

const Tags = () => {
  const [selectedPartner, setSelectedPartner] = useState<string>(() => {
    const storedPartner = localStorage.getItem('selectedPartner');
    return storedPartner ? JSON.parse(storedPartner).id : '';
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [tagTypes, setTagTypes] = useState<TagType[]>([]);
  const [personaTags, setPersonaTags] = useState<PersonaTag[]>([]);
  const [newTagTypeName, setNewTagTypeName] = useState<string>('');
  const [newParentTagTypeName, setNewParentTagTypeName] = useState<string>('');
  const [newPersonaTagName, setNewPersonaTagName] = useState<string>('');
  const [selectedParentTagId, setSelectedParentTagId] = useState<number | null>(
    null
  );
  const [selectedTagTypeId, setSelectedTagTypeId] = useState<number>(0);
  const [newPersonaTagPriority, setNewPersonaTagPriority] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<any>(null);

  const openModal = (tag: any) => {
    setSelectedTag(tag);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedTag(null);
  };
  const toast = useToast();
  const { login, getToken } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const inputTextColor = useColorModeValue('black', 'white');
  const [tagToDelete, setTagToDelete] = useState<any>(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: openDelete,
    onClose: deleteOnClose,
  } = useDisclosure();
  const [parentTagTypes, setParentTagTypes] = useState([]);
  const [addTagTypeSelected_parentType, setAddTagTypeSelected_parentType] =
    useState<any>(null);

  const handleDelete = (tag: any) => {
    setTagToDelete(tag);
    openDelete();
  };

  const [displaySelectedParentTagType, setDisplaySelectedParentTagType] =
    useState<any>(null);
  const [displaySelectedTagType, setDisplaySelectedTagType] =
    useState<any>(null);
  const [filteredDisplayTagTypes, setFilteredDisplayTagTypes] = useState<any[]>(
    []
  );
  const [filteredParentDisplayTags, setFilteredParentDisplayTags] = useState<
    any[]
  >([]);
  const [filteredDisplayTags, setFilteredDisplayTags] = useState<any[]>([]);

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-persona-tag`,
        {
          tag_id: selectedTag.tag_id,
          persona_id: selectedTag.persona_id,
          tag_type_id: selectedTag.tag_type_id,
          priority: selectedTag.priority,
          tag: selectedTag.tag,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200 && response.data.success) {
        console.log(response.data);
        closeModal();
      } else {
        console.error('Failed to update tag:', response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
      fetchPersonaTags(selectedPersona!.id);
    }
  };

  const handleParentTagTypeChange = (e: any) => {
    console.log('e.target.value');
    console.log(e.target.value);
    setDisplaySelectedParentTagType(parseInt(e.target.value));
  };

  const handleTagTypeChange = (e: any) => {
    setDisplaySelectedTagType(parseInt(e.target.value));
  };

  const handleConfirmDelete = async () => {
    if (!tagToDelete) return;
    const token = await getToken();
    axios
      .delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-tag/${tagToDelete.tag_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          deleteOnClose();
          setTagToDelete(null);
          fetchPersonaTags(selectedPersona!.id);
        } else {
          setTagToDelete(null);
          console.error('Failed to delete tag.');
        }
      })
      .catch((error) => {
        console.error('Error deleting tag:', error);
      });
  };

  const {
    isOpen: isParentTagTypeModalOpen,
    onOpen: onParentTagTypeModalOpen,
    onClose: onParentTagTypeModalClose,
  } = useDisclosure();

  const {
    isOpen: isTagTypeModalOpen,
    onOpen: onTagTypeModalOpen,
    onClose: onTagTypeModalClose,
  } = useDisclosure();
  const {
    isOpen: isPersonaTagModalOpen,
    onOpen: onPersonaTagModalOpen,
    onClose: onPersonaTagModalClose,
  } = useDisclosure();

  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(() => {
    const storedPersona = localStorage.getItem('selectedPersona');
    return storedPersona ? JSON.parse(storedPersona) : null;
  });

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-partners`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      const filteredPartners = response.data.filter(
        (partner: Partner) =>
          partner.name === 'state_banking_compliance' ||
          partner.name === 'federal_banking_compliance'
      );
      setPartners(filteredPartners);
    } catch (error) {
      console.error('Error fetching partners:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching partners.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPersonas = async (partnerId: number) => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-personas/${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setPersonas(response.data);
    } catch (error) {
      console.error('Error fetching personas:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching personas.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchTagTypes = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-tag-types`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setTagTypes(response.data);
    } catch (error) {
      console.error('Error fetching tag types:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching tag types.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPersonaTags = async (personaId: number) => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-persona-tags/${personaId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setPersonaTags(response.data);
    } catch (error) {
      console.error('Error fetching persona tags:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching persona tags.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePersonaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selectedPersonaObj = personas.find(
      (persona) => persona.id.toString() === selectedId
    );

    if (selectedPersonaObj) {
      setSelectedPersona(selectedPersonaObj);
      fetchPersonaTags(selectedPersonaObj.id);
      localStorage.setItem(
        'selectedPersona',
        JSON.stringify(selectedPersonaObj)
      );
    }
  };

  const handleAddParentTagType = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/add-parent-tag-type`,
        {
          name: newParentTagTypeName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.success) {
        setNewParentTagTypeName('');
        fetchTagTypes();
        toast({
          title: 'Tag Type Added',
          description: 'New tag type has been successfully added.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        onParentTagTypeModalClose(); // Close the modal
      } else {
        toast({
          title: 'Error',
          description: 'Failed to add new tag type.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Error adding tag type:', error);
      toast({
        title: 'Error',
        description: 'Failed to add new tag type.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddTagType = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/add-tag-type`,
        {
          name: newTagTypeName,
          parent_tag_id: addTagTypeSelected_parentType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.success) {
        setNewTagTypeName('');
        fetchTagTypes();
        toast({
          title: 'Tag Type Added',
          description: 'New tag type has been successfully added.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        onTagTypeModalClose(); // Close the modal
      } else {
        toast({
          title: 'Error',
          description: 'Failed to add new tag type.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Error adding tag type:', error);
      toast({
        title: 'Error',
        description: 'Failed to add new tag type.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddPersonaTag = async () => {
    try {
      setLoading(true);

      const tagTypeId = selectedTagTypeId ?? selectedParentTagId;
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/add-persona-tag`,
        {
          persona_id: selectedPersona?.id,
          tag_type_id: tagTypeId,
          priority: newPersonaTagPriority,
          tag: newPersonaTagName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.success) {
        setNewPersonaTagName('');
        fetchPersonaTags(selectedPersona!.id); // Refresh persona tags
        toast({
          title: 'Persona Tag Added',
          description: 'New persona tag has been successfully added.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        onPersonaTagModalClose(); // Close the modal
      } else {
        toast({
          title: 'Error',
          description: 'Failed to add new persona tag.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Error adding persona tag:', error);
      toast({
        title: 'Error',
        description: 'Failed to add new persona tag.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      if (selectedPersona!.id) {
        fetchPersonaTags(selectedPersona!.id);
      }
      setLoading(false);
    }
  };

  const fetchParentTagTypes = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-parent-tag-types`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setParentTagTypes(response.data);
    } catch (error) {
      console.error('There was an error fetching the parent tag types!', error);
    }
  };

  const filteredTagTypes = selectedParentTagId
    ? tagTypes.filter(
        (tagType: any) => tagType.parent_tag_id === selectedParentTagId
      )
    : tagTypes.filter((tagType: any) => tagType.parent_tag_id === null);

  useEffect(() => {
    if (isTagTypeModalOpen) {
      fetchParentTagTypes();
      setAddTagTypeSelected_parentType(null);
    }
  }, [isTagTypeModalOpen]);

  useEffect(() => {
    if (isPersonaTagModalOpen) {
      fetchParentTagTypes();
    }
  }, [isPersonaTagModalOpen]);

  useEffect(() => {
    if (!profile?.idToken || !profile?.userId) {
      login();
    }
    if (!profile?.isAdmin) {
      console.log('NOT ADMIN');
    } else {
      fetchPartners();
      fetchTagTypes();
      console.log('ADMIN');
    }
  }, []);

  useEffect(() => {
    if (displaySelectedParentTagType !== null) {
      const filteredTags = personaTags.filter(
        (tag: any) =>
          tag.parent_tag_type_id === displaySelectedParentTagType ||
          tag.tag_type_id === displaySelectedParentTagType
      );
      const filteredTypes = personaTags
        .filter(
          (tag: any) => tag.parent_tag_type_id === displaySelectedParentTagType
        )
        .map((tag) => ({ id: tag.tag_type_id, name: tag.tag_type_name }))
        .filter(
          (value, index, self) =>
            self.findIndex((v) => v.id === value.id) === index
        );
      setFilteredDisplayTags([]);
      setFilteredDisplayTagTypes(filteredTypes);
      setFilteredParentDisplayTags(filteredTags);
      setDisplaySelectedTagType(null);
    }
  }, [displaySelectedParentTagType, personaTags]);

  useEffect(() => {
    if (displaySelectedTagType !== null) {
      const filteredTags = personaTags.filter(
        (tag) => tag.tag_type_id === displaySelectedTagType
      );
      setFilteredDisplayTags(filteredTags);
    }
  }, [displaySelectedTagType, personaTags]);

  useEffect(() => {
    if (selectedPartner) {
      fetchPersonas(parseInt(selectedPartner));
    }
    if (selectedPersona) {
      fetchPersonaTags(selectedPersona.id);
    }
  }, [selectedPartner, selectedPersona]);

  return (
    <Box p={8} maxW="7xl" mx="auto" mt={{ xl: '50px' }}>
      {profile ? (
        !profile.isAdmin ? (
          <Flex justifyContent="center" alignItems="center" minH="200px">
            <Alert
              status="error"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              maxW="400px"
              borderRadius="lg"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={2} fontSize="lg">
                Access Denied
              </AlertTitle>
              <AlertDescription maxWidth="sm" mb={4}>
                You do not have permission to access this page.
              </AlertDescription>
              <Button
                colorScheme="blue"
                onClick={() => (window.location.href = '/')}
              >
                Go to Home
              </Button>
            </Alert>
          </Flex>
        ) : (
          <>
            <Select
              placeholder="Select partner"
              onChange={(e) => {
                const selected = partners.find(
                  (partner) => partner.id === parseInt(e.target.value)
                );
                setSelectedPartner(e.target.value);

                if (selected?.id) {
                  localStorage.setItem(
                    'selectedPartner',
                    JSON.stringify({ id: selected.id, name: selected.name })
                  );
                }
                fetchPersonas(selected!.id);
              }}
              mb={4}
              value={selectedPartner}
            >
              {partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.display_name}
                </option>
              ))}
            </Select>
            <Select
              placeholder="Select persona"
              onChange={handlePersonaChange}
              mb={4}
              isDisabled={!selectedPartner}
              value={selectedPersona?.id || ''}
            >
              {personas.map((persona) => (
                <option key={persona.id} value={persona.id}>
                  {persona.display_name}
                </option>
              ))}
            </Select>
            <Modal
              isOpen={isParentTagTypeModalOpen}
              onClose={onParentTagTypeModalClose}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Parent Tag Type</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Parent Tag Type Name</FormLabel>
                    <Input
                      placeholder="Enter parent tag type name"
                      value={newParentTagTypeName}
                      color={inputTextColor}
                      onChange={(e) => setNewParentTagTypeName(e.target.value)}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleAddParentTagType}>
                    Add
                  </Button>
                  <Button ml={3} onClick={onParentTagTypeModalClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Tag Type Modal */}
            <Modal isOpen={isTagTypeModalOpen} onClose={onTagTypeModalClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Tag Type</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl mt={4} mb={4}>
                    <FormLabel>Parent Tag</FormLabel>
                    <Select
                      placeholder="Select parent tag type"
                      onChange={(e) =>
                        setAddTagTypeSelected_parentType(e.target.value)
                      }
                    >
                      {parentTagTypes.map((parentTagType) => (
                        <option key={parentTagType.id} value={parentTagType.id}>
                          {parentTagType.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Tag Type Name</FormLabel>
                    <Input
                      placeholder="Enter tag type name"
                      value={newTagTypeName}
                      color={inputTextColor}
                      onChange={(e) => setNewTagTypeName(e.target.value)}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleAddTagType}>
                    Add
                  </Button>
                  <Button ml={3} onClick={onTagTypeModalClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Persona Tag Modal */}
            <Modal
              isOpen={isPersonaTagModalOpen}
              onClose={onPersonaTagModalClose}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Persona Tag</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl mb={4}>
                    <FormLabel>Parent Tag</FormLabel>
                    <Select
                      placeholder="Select parent tag type"
                      value={selectedParentTagId ?? ''}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setSelectedParentTagId(
                          selectedValue ? parseInt(selectedValue) : null
                        );
                        setSelectedTagTypeId(null); // Reset the current tag type selection when the parent tag type changes
                      }}
                      color={inputTextColor}
                    >
                      {parentTagTypes.map((parentTag) => (
                        <option key={parentTag.id} value={parentTag.id}>
                          {parentTag.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Tag Type</FormLabel>
                    <Select
                      placeholder="Select tag type"
                      value={selectedTagTypeId ?? ''}
                      onChange={(e) =>
                        setSelectedTagTypeId(parseInt(e.target.value))
                      }
                      color={inputTextColor}
                      disabled={!selectedParentTagId} // Disable if no parent tag type is selected
                    >
                      {filteredTagTypes.map((tagType) => (
                        <option key={tagType.id} value={tagType.id}>
                          {tagType.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Tag Name</FormLabel>
                    <Input
                      placeholder="Enter tag name"
                      value={newPersonaTagName}
                      onChange={(e) => setNewPersonaTagName(e.target.value)}
                      color={inputTextColor}
                    />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Priority</FormLabel>
                    <Input
                      placeholder="Enter priority"
                      type="number"
                      value={newPersonaTagPriority ?? ''}
                      onChange={(e) =>
                        setNewPersonaTagPriority(parseInt(e.target.value))
                      }
                      color={inputTextColor}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleAddPersonaTag}>
                    Add
                  </Button>
                  <Button ml={3} onClick={onPersonaTagModalClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal isOpen={isOpen} onClose={closeModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Tag</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl id="tag-type-id">
                    <FormLabel>Tag Type</FormLabel>
                    <Select
                      placeholder="Select tag type"
                      value={selectedTag?.tag_type_id ?? ''}
                      onChange={(e) =>
                        setSelectedTag({
                          ...selectedTag,
                          tag_type_id: parseInt(e.target.value),
                        })
                      }
                      color={inputTextColor}
                    >
                      {tagTypes.map((tagType) => (
                        <option key={tagType.id} value={tagType.id}>
                          {tagType.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl id="tag">
                    <FormLabel>Tag</FormLabel>
                    <Input
                      type="text"
                      value={selectedTag?.tag}
                      onChange={(e) =>
                        setSelectedTag({ ...selectedTag, tag: e.target.value })
                      }
                      color={inputTextColor}
                    />
                  </FormControl>
                  <FormControl id="priority">
                    <FormLabel>Priority</FormLabel>
                    <Input
                      type="number"
                      value={selectedTag?.priority}
                      onChange={(e) =>
                        setSelectedTag({
                          ...selectedTag,
                          priority: e.target.value,
                        })
                      }
                      color={inputTextColor}
                    />
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={handleUpdate}
                    isLoading={isLoading}
                  >
                    Save
                  </Button>
                  <Button variant="ghost" onClick={closeModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal isOpen={isDeleteOpen} onClose={deleteOnClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalCloseButton />
                <ModalBody>Are you sure you want to delete this tag?</ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    onClick={handleConfirmDelete}
                  >
                    Delete
                  </Button>
                  <Button variant="ghost" onClick={deleteOnClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Display tag types and persona tags */}
            <Stack spacing={4}>
              {selectedPersona && (
                <FormControl>
                  <FormLabel>Select Parent Tag Type</FormLabel>
                  <Select
                    placeholder="Select parent tag type"
                    onChange={handleParentTagTypeChange}
                  >
                    {personaTags
                      .filter(
                        (tag: any) =>
                          tag.parent_tag_type_id === null ||
                          tag.parent_tag_type_id !== null
                      )
                      .map((tag: any) => ({
                        id: tag.parent_tag_type_id || tag.tag_type_id,
                        name: tag.parent_tag_type_name || tag.tag_type_name,
                      }))
                      .filter(
                        (value, index, self) =>
                          self.findIndex((v) => v.id === value.id) === index
                      )
                      .map((parentTagType) => (
                        <option key={parentTagType.id} value={parentTagType.id}>
                          {parentTagType.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              )}
              {filteredParentDisplayTags && (
                <Stack direction="row" spacing={2} flexWrap="wrap">
                  {filteredParentDisplayTags.map((tag) => (
                    <Tag key={tag.tag_id} variant="subtle" colorScheme="blue">
                      <TagLeftIcon boxSize="12px" as={MdLabel} mb={2} />
                      <TagLabel>{tag.tag}</TagLabel>
                      <IconButton
                        aria-label="Edit tag"
                        icon={<MdEdit />}
                        size="sm"
                        variant="ghost"
                        colorScheme="blue"
                        onClick={() => openModal(tag)}
                      />
                      <IconButton
                        aria-label="Delete tag"
                        icon={<MdDelete />}
                        size="sm"
                        variant="ghost"
                        colorScheme="red"
                        onClick={() => handleDelete(tag)}
                      />
                    </Tag>
                  ))}
                </Stack>
              )}

              {filteredDisplayTagTypes.length > 0 && (
                <FormControl>
                  <FormLabel>Select Tag Type</FormLabel>
                  <Select
                    placeholder="Select tag type"
                    onChange={handleTagTypeChange}
                  >
                    {filteredDisplayTagTypes.map((tagType) => (
                      <option key={tagType.id} value={tagType.id}>
                        {tagType.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Stack direction="row" spacing={2} flexWrap="wrap">
                {filteredDisplayTags.map((tag) => (
                  <Tag key={tag.tag_id} variant="subtle" colorScheme="blue">
                    <TagLeftIcon boxSize="12px" as={MdLabel} />
                    <TagLabel>{tag.tag}</TagLabel>
                    <IconButton
                      aria-label="Edit tag"
                      icon={<MdEdit />}
                      size="sm"
                      variant="ghost"
                      colorScheme="blue"
                      onClick={() => openModal(tag)}
                    />
                    <IconButton
                      aria-label="Delete tag"
                      icon={<MdDelete />}
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => handleDelete(tag)}
                    />
                  </Tag>
                ))}
              </Stack>

              {loading && (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                  mb={4}
                />
              )}
            </Stack>

            <Button
              mb={4}
              mt={4}
              mr={4}
              colorScheme="blue"
              onClick={onParentTagTypeModalOpen}
            >
              Add parent Tag Type
            </Button>
            <Button
              mb={4}
              mt={4}
              mr={4}
              colorScheme="blue"
              onClick={onTagTypeModalOpen}
            >
              Add Tag Type
            </Button>
            <Button
              mb={4}
              mt={4}
              mr={4}
              colorScheme="blue"
              onClick={onPersonaTagModalOpen}
              isDisabled={!selectedPersona}
            >
              Add Tag
            </Button>
          </>
        )
      ) : null}
    </Box>
  );
};

export default Tags;
